import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

import "./404.css"

const NotFoundPage = () => (
  <Layout>
    <div className="main-404">
      <SEO title="404: Not found" />
      <div className="section">
        <h1>NOT FOUND</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
        <button>
          <Link to="/">Go Back</Link>
        </button>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
